<template>
	<div
		class="ma-10"
	>
		<v-text-field
			v-model="item.from"
			label="from"
		>
		</v-text-field>
		<v-text-field
			v-model="item.to"
			label="to"
		>
		</v-text-field>
		<v-textarea
			v-model="item.message"
			label="message"
		>
		</v-textarea>
		<v-btn
			@click="send"
		>
			send sms
		</v-btn>
	</div>
</template>
<script>


	export default{
		name: 'test'
		,created: function(){
			
		}
		,props: ['callBack']
		,data: function(){
			return {
				item: {
					from: ''
					,to: ''
					,message: ''
				}
			}
		}
		
		,methods: {
			send: function(){
				this.$emit('axios', {
					request_type: 'post'
					,origin_url: 'https://service.theapi.co/api/sms'
					,request_header: {
						'Authorization': 'Basic paylink:XDuwix1338'
					}
					,request_params: this.item
					,callback: 'result'
				})
			}
			,result: function(call){
				console.log(call)
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'result'){
						this.result(call)
					}
				}
			}
		}
	}
</script>
